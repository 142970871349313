<template>
  <b-row>
    <b-col sm="12" md="6">
      <my-card :title="data.code">
        <template slot="body">
          <dl class="row">
            <dt class="col-sm-3">Cabang</dt>
            <dt class="col-sm-9">{{data.branch_name}}</dt>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">Tanggal</dt>
            <dt class="col-sm-9">{{data.date | mindate}}</dt>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">Tipe</dt>
            <dt class="col-sm-9">{{data.name_type}}</dt>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">Catatan</dt>
            <dt class="col-sm-9">{{data.note}}</dt>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">Di input oleh</dt>
            <dt class="col-sm-9">{{data.user_create}}</dt>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">Di Update Oleh</dt>
            <dt class="col-sm-9">{{data.user_update}}</dt>
          </dl>
        </template>
      </my-card>
    </b-col>
    <b-col sm="12">
      <my-card title="Detail Item">
        <template slot="body">
          <b-row>
            <b-col sm="12">
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th>Item</b-th>
                    <b-th>Satuan</b-th>
                    <b-th>Stok Real</b-th>
                    <b-th>Stok Selisih</b-th>
                    <b-th>Catatan</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(tr,i) in data.stock_opname_details" :key="i">
                    <b-td>{{tr.name_item}}</b-td>
                    <b-td>{{tr.name_unit}}</b-td>
                    <b-td>{{tr.stock_real | formatNumber}}</b-td>
                    <b-td>{{tr.stock_difference | formatNumber}}</b-td>
                    <b-td>{{tr.note}}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col sm="12" class="mt-3" offset-md="8" md="8">
              <dl class="row">
                <dt class="col-sm-3">Selisih +</dt>
                <dt class="col-sm-6">{{qtyPlus | formatNumber}}</dt>
              </dl>
              <dl class="row">
                <dt class="col-sm-3">Selisih -</dt>
                <dt class="col-sm-6">{{qtyMin | formatNumber}}</dt>
              </dl>
              <dl class="row">
                <dt class="col-sm-3">Total</dt>
                <dt class="col-sm-6">{{data.total | formatNumber}}</dt>
              </dl>
            </b-col>
          </b-row>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import {BTableSimple,BThead,BTbody,BTh,BTd,BTr} from 'bootstrap-vue'
export default {
  components:{
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTd,
    BTr
  },
  data(){
    return {
      data:{
        code:"",
        stock_opname_details:[]
      }
    }
  },
  computed:{
    qtyMin(){
      let total = 0
      for (let i = 0; i < this.data.stock_opname_details.length; i++) {
        const e = this.data.stock_opname_details[i];
        if(e.stock_difference>=0) continue
        total += parseFloat(e.stock_difference)
      }
      return Math.abs(total)
    },
    qtyPlus(){
      let total = 0
      for (let i = 0; i < this.data.stock_opname_details.length; i++) {
        const e = this.data.stock_opname_details[i];
        if(e.stock_difference<0) continue
        total += parseFloat(e.stock_difference)
      }
      return total
    },
  },
  methods:{
    async getData(){
      try {
        const id = this.$route.params.id
        const data = await this.$store.dispatch('stockopnames/show',id)
        this.data = data
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>